/**
 * Main content container
 */
.main {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, .01));
    flex: 1 0 auto;
    margin-bottom: -10em;
    margin-inline: auto;
    max-width: calc(var(--content-width));
    min-height: 100%;
    padding: 4em 2em 10em;
    position: relative;
    width: 100%;
    z-index: 5;

    .scrolled--max & {
        padding-bottom: 36em;

        @media (--desktop) {
            padding-bottom: 32em;
        }
    }
}
