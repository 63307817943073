/**
 * <html>
 */
html {
    font-size: .75rem;

    @media (--desktop) {
        font-size: 1rem;
    }
}
