/**
 * Header navigation
 *
 * nav
 * - ul
 * -- li
 * --- a
 * --- ul
 * ---- …
 */
.main-navigation {
    display: none;

    a {
        text-decoration: none;
    }

    >ul {

        >.is--mobile-nav {
            @media (--desktop) {
                display: none;
            }
        }

        >.menu-item-has-children {

            >a {
                align-items: center;
                display: flex;

                @media (--desktop) {
                    pointer-events: none;
                }

                &::after {
                    border-bottom: .375em solid transparent;
                    border-left: .6em solid var(--wp--preset--color--black);
                    border-top: .375em solid transparent;
                    content: "";
                    margin-left: .375em;
                    position: relative;
                    top: .0875em;

                    @media (--desktop) {
                        display: none;
                    }
                }
            }
        }

        >.subnav-is--visible {

            >a {

                &::after {
                    border: 0;
                    border-left: .375em solid transparent;
                    border-right: .375em solid transparent;
                    border-top: .6em solid var(--wp--preset--color--black);
                }
            }
        }

        >li {

            >a {
                @extend %copy--nav;
            }

            &.subnav-is--visible {

                >ul {
                    display: block;
                }
            }

            >ul {
                display: none;
            }
        }
    }

    @media (--desktop-full) {
        display: initial;
        margin-left: auto;
        position: relative;
        z-index: 1;

        /* Lvl 1: Container */
        >ul {
            display: inline-flex;
            gap: 1em;

            /* Lvl 1: List item */
            >li {
                padding-top: .875rem;
                position: relative;

                /* Lvl 1: Link */
                >a {
                    align-items: center;
                    display: inline-flex;
                    height: 4rem;
                    text-transform: uppercase;
                }

                /* Lvl 1: Link:hover */
                &:hover {

                    >a {
                        color: var(--wp--preset--color--orange);
                    }

                    >ul {
                        display: block;
                    }
                }

                /* Lvl 1: Active */
                &.current-menu-ancestor,
                &.current-menu-item {

                    >a {
                        color: var(--wp--preset--color--orange);
                    }
                }

                &.menu-item--mobile {
                    display: none;
                }

                &.menu-item-has-children {

                    &:hover {

                        >ul > :first-child {

                            &:hover::before {
                                border-bottom: .75em solid var(--wp--preset--color--orange);
                            }

                            &::before {
                                border-bottom: .75em solid var(--wp--preset--color--cyan);
                                border-left: .5em solid transparent;
                                border-right: .5em solid transparent;
                                bottom: 100%;
                                content: "";
                                left: 1em;
                                position: absolute;
                            }
                        }
                    }
                }

                /* Lvl 2: Container */
                >ul {
                    background-color: var(--wp--preset--color--black);
                    color: var(--wp--preset--color--cyan);
                    display: none;
                    left: 0;
                    position: absolute;
                    top: 100%;
                    width: 20em;

                    /* Lvl 2: List item */
                    >li {
                        /* Lvl 2: Link */
                        >a {
                            border-top: 1px solid var(--wp--preset--color--cyan);
                            color: inherit;
                            display: block;
                            padding-block: .5em;
                            text-align: center;

                            &:hover {
                                background-color: var(--wp--preset--color--cyan);
                                color: var(--wp--preset--color--black);
                            }
                        }

                        /* Lvl 2: Link:hover */
                        &:hover,
                        &.current-menu-ancestor,
                        &.current-menu-item {

                            >a {
                                background-color: var(--wp--preset--color--orange);
                                border-color: var(--wp--preset--color--orange);
                                color: var(--wp--preset--color--black);
                            }

                            & + li > a {
                                border-color: var(--wp--preset--color--orange);
                            }
                        }

                        >ul {

                            >li {
                                border-top: 1px solid var(--wp--preset--color--cyan);

                                >a {
                                    color: inherit;
                                    display: block;
                                    font-size: .875rem;
                                    padding-block: .5rem;
                                    text-align: center;

                                    &:hover {
                                        background-color: var(--wp--preset--color--cyan);
                                        color: var(--wp--preset--color--black);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .nav-is--visible & {
        background-color: var(--wp--preset--color--cyan);
        color: var(--wp--preset--color--black);
        display: initial;
        inset: 3.125rem 0 0;
        padding-top: 6.25rem;
        position: fixed;
        text-align: center;

        >ul {

            >li {

                &:not(:first-child) {
                    border-top: 1px solid var(--wp--preset--color--black);
                }

                >a {
                    align-items: center;
                    display: flex;
                    font-size: 1.375rem;
                    justify-content: center;
                    line-height: 1;
                    padding-block: .5em;
                    text-transform: uppercase;
                }

                >ul {
                    background-color: var(--wp--preset--color--black);
                    color: var(--wp--preset--color--cyan);

                    >li {

                        >a {
                            display: block;
                            padding-block: .5em;
                        }
                    }
                }
            }
        }
    }
}
