/**
 * <a>
 */
a {
    color: inherit;
    transition: all var(--animation__speed);

    &:not([class]) {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
