/**
 * Archive template
 */
.archive {

    &__title {
        @extend %copy--headliner--large;
        text-align: center;
        text-transform: uppercase;
    }
}
