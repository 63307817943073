/**
 * Toggle navigation button
 */
.navigation-toggle {
    --component-color: var(--wp--preset--color--cyan);
    background-color: transparent;
    border: 0;
    height: 3.125rem;
    padding: 0;
    position: absolute;
    right: 1.5rem;
    top: 0;
    width: 3.75rem;

    @media (--desktop) {
        top: .875em;
    }

    @media (--desktop-full) {
        display: none;
    }

    &:hover {
        background-color: var(--wp--preset--color--cyan);
        --component-color: var(--wp--preset--color--black);
    }

    &__icon {
        --component-height: .25rem;
        background-color: var(--component-color);
        display: inline-block;
        height: var(--component-height);
        left: .75rem;
        position: relative;
        transition: all .2s;
        width: 2.25rem;

        &::before,
        &::after {
            background-color: var(--component-color);
            content: "";
            height: var(--component-height);
            left: 0;
            position: absolute;
            right: 0;
            transition: all .2s;
        }

        &::before {
            bottom: calc(100% + var(--component-height));
        }

        &::after {
            top: calc(100% + var(--component-height));
        }
    }

    .nav-is--visible & {
        --component-color: var(--wp--preset--color--black);
        background-color: var(--wp--preset--color--cyan);

        &__icon {
            background-color: transparent;

            &::before {
                bottom: calc(50% - (var(--component-height) / 2));
                transform: rotate(45deg);
            }

            &::after {
                top: calc(50% - (var(--component-height) / 2));
                transform: rotate(-45deg);
            }
        }
    }
}
