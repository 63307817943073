/**
 * Input
 */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
}

/**
 * Input options
 */
input[type="checkbox"],
input[type="radio"] {
    width: auto;
}

/**
 * Submit button
 */
button[type="submit"],
input[type="submit"] {
    @extend %button--primary !optional;
}

/**
 * Textarea
 */
textarea {
    resize: vertical;
}

/**
 * Button
 */
button,
.button {
    @extend %button;
}

.button--large {
    @extend %button--large;
}
