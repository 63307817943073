/**
 * <body>
 */
body {
    background-color: var(--wp--preset--color--cyan);
    color: var(--wp--preset--color--black);
    display: flex;
    flex-direction: column;
    font-family: var(--wp--preset--font-family--copy);
    font-size: 1rem;
    line-height: calc(1ex / .32);
    min-height: 100vh;
    overflow-x: hidden;

    &::before {
        aspect-ratio: 746 / 493;
        background: url("../img/bg-sun.svg") center top no-repeat;
        background-size: contain;
        content: "";
        left: 50%;
        max-width: 746px;
        pointer-events: none;
        position: absolute;
        top: -3.25em;
        transform: translateX(-50%);
        width: 100%;
        z-index: -1;

        @media (--desktop) {
            left: calc(50% - (var(--content-width) / 2) + (var(--logo-size) / 2));
            top: 0;
        }
    }

    html &.custom-background {
        background-attachment: scroll;
        background-color: #E55626;
        background-image: linear-gradient(to bottom, #FC7CA0, #E8BC3C 20%, #E55626 30%, #59535C 50%, #0A527B 60%, #00003A 80%, #E55626);
        background-repeat: repeat-x;
        background-size: 2560px 100%;
    }

    &::after {
        background: url("../img/bg-texture.png") no-repeat;
        background-size: cover;
        content: "";
        inset: 0;
        pointer-events: none;
        position: fixed;
    }
}

.admin-bar {
    --adminbar__height: 46px;
    min-height: calc(100vh - var(--adminbar__height));

    @media (--adminbar--full) {
        --adminbar__height: 32px;
        min-height: calc(100vh - var(--adminbar__height));
    }
}
