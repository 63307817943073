.line-up {
    margin-bottom: 3em;

    &__day {
        @extend %copy--overhead;
        background-color: var(--wp--preset--color--black);
        color: var(--wp--preset--color--orange);
        margin-bottom: .5em;
        padding-block: .2em .375em;
        text-align: center;

        &:not(:first-child) {
            margin-top: 2em;
        }
    }

    &__group {
        display: flex;
        flex-wrap: wrap;
        gap: 0 1rem;
        justify-content: center;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    &__act {
        line-height: 1;
        list-style: none;
        // margin: 0 0 0 -1rem;
        text-decoration: none;
        text-transform: uppercase;

        &::before {
            content: "• ";
        }

        &--first,
        &:first-child {

            &::before {
                visibility: hidden;
            }
        }

        &--headliner {
            @extend %copy--headliner--large;
        }

        &--band {
            @extend %copy--headliner;
        }

        &--support {
            @extend %copy--support;
        }
    }

    &__link {
        text-decoration: none;

        &:hover {
            color: var(--wp--preset--color--orange);
        }
    }
}
