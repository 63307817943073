.time-table {
    width: 100%;

    thead {
        background-color: var(--wp--preset--color--black);

        th {
            @extend %copy--overhead;
            color: var(--wp--preset--color--cyan);
            font-weight: normal;
            padding-block: 1em;
        }
    }

    tbody {

        th,
        td {
            border-bottom: 1px solid rgba(0, 0, 0, .2);
        }
    }

    &__time {
        font-weight: normal;
        padding-block: .25em;
        width: 3em;
    }

    &__gig {
        position: relative;

        &-link {
            background-color: var(--wp--preset--color--orange);
            border-left: .25em solid var(--wp--preset--color--black);
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            left: 0;
            padding: 1em;
            position: absolute;
            right: 0;
            text-decoration: none;
            top: 0;

            &:hover,
            &:focus {
                background-color: var(--wp--preset--color--black);
                color: var(--wp--preset--color--white);
            }
        }

        &-time {
            @extend %copy;
        }

        &-name {
            @extend %copy--news;
            text-transform: uppercase;
        }
    }
}
