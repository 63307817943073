/**
 * Copy text styles
 *
 * Define global copy text styles
 */
%copy {
    font-family: var(--wp--preset--font-family--copy);
}

%copy--small {
    font-family: var(--wp--preset--font-family--style);
    font-size: .75rem;
}

%copy--nav {
    font-family: var(--wp--preset--font-family--style);
    font-size: 1.125rem;
}

%copy--overhead {
    font-family: var(--wp--preset--font-family--style);
    font-size: 1.5rem;
}

%copy--news {
    font-family: var(--wp--preset--font-family--headline);
    font-size: 1.25rem;
    font-weight: bold;
}

%copy--headliner {
    font-family: var(--wp--preset--font-family--heading);
    font-size: 1.5rem;
    font-weight: bold;

    @media (--desktop) {
        font-size: 2rem;
    }
}

%copy--support {
    font-family: var(--wp--preset--font-family--heading);
    font-size: 1rem;
    font-weight: bold;

    @media (--desktop) {
        font-size: 1.125rem;
    }
}

%copy--headliner--large {
    @extend %copy--headliner;
    font-size: 2.5rem;

    @media (--desktop) {
        font-size: 3.5rem;
    }
}
