.wp-block-rocco-accordion {
    background-color: var(--wp--preset--color--white);

    & + & {
        margin-top: .25em;
    }
}

.accordion {

    &__title {
        align-items: center;
        background-color: var(--wp--preset--color--black);
        color: var(--wp--preset--color--cyan);
        cursor: pointer;
        display: flex;
        font-size: 1.125rem;
        padding: 1rem 4rem 1rem 1rem;
        position: relative;

        &:hover {

            &::after {
                background-color: var(--wp--preset--color--orange);
            }
        }

        &::after {
            align-items: center;
            background-color: var(--wp--preset--color--cyan);
            border-radius: 50%;
            color: var(--wp--preset--color--black);
            content: "+";
            display: inline-flex;
            flex: 0;
            font-size: 3rem;
            height: 2rem;
            justify-content: center;
            position: absolute;
            right: 1rem;
            text-align: center;
            top: .75rem;
            transform: rotate(0deg);
            transition: all .2s;
            vertical-align: center;
            width: 2rem;

            [open] > & {
                background-color: var(--wp--preset--color--orange);
                transform: rotate(45deg);
            }
        }
    }

    &__body {
        padding: 1rem;
    }
}
