.single-gig {

    .main {

        @media(--desktop) {
            padding-inline: 0;
        }
    }

    .entry {

        &__wrapper {
            grid-template-areas:
                "video"
                "content"
                "sidebar";

            @media (--desktop) {
                grid-template-areas:
                    "sidebar video"
                    "sidebar content";
            }
        }
    }
}

.gig-datetime {
    margin-top: 1em;

    &__stage {
        @extend .button--large;
        margin-bottom: .5em;
    }

    &__date,
    &__time {
        @extend %copy--news;
        padding-left: 1em;
    }
}