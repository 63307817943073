.cloud {
    animation: drift var(--duration) ease-in-out infinite;
    background-image: var(--img);
    height: var(--height);
    left: var(--left);
    position: fixed;
    top: var(--top);
    width: var(--width);
    z-index: 0;

    &-1 {
        --duration: 12s;
        --delay: 10s;
        --top: 14em;
        --left: 10%;
        --width: 325px;
        --height: 108px;
        --img: url("../img/cloud-1.svg");
    }

    &-2 {
        --duration: 8s;
        --top: 25em;
        --left: 70%;
        --width: 243px;
        --height: 92px;
        --img: url("../img/cloud-2.svg");
    }

    &-3 {
        --duration: 17s;
        --top: 40em;
        --left: 20%;
        --width: 321px;
        --height: 178px;
        --img: url("../img/cloud-3.svg");
    }

    &-4 {
        --duration: 9s;
        --top: 37em;
        --left: 65%;
        --width: 186px;
        --height: 119px;
        --img: url("../img/cloud-4.svg");
    }
}

@keyframes drift {

    0% {
        transform: translateY(-10%);
    }

    50% {
        transform: translateY(10%);
    }

    100% {
        transform: translateY(-10%);
    }
}
