/**
 * <figure>
 */
figure {
    margin: 0;
}

figcaption {

}
