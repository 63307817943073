.excerpt {

    &__more {
        display: block;
        font-size: 4rem;
        line-height: 1;
        margin-top: -.5em;
        text-decoration: none;

        &:hover {
            color: var(--wp--preset--color--orange);
        }
    }
}
