.tax-festival {

    .main {
        background: none;
    }

    .posts {
        display: grid;
        gap: 1.25rem 3.75rem;
        grid-template-columns: repeat(2, 1fr);
    }
}

.lineup {
    background-color: rgba(255, 255, 255, .75);
    padding-bottom: 1em;

    &__header {
        align-items: center;
        border-bottom: .25em solid var(--wp--preset--color--orange);
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1em;
        margin-right: 1em;
        min-height: 6.75rem;
        padding-block: .75em;
        padding-left: 1em;
        text-align: right;
    }

    &__title {
        margin: 0;
        text-transform: uppercase;

        a {
            text-decoration: none;

            &:hover {
                color: var(--wp--preset--color--orange);
            }
        }
    }

    &__image {
        float: left;
        margin-left: -2.25em;
        margin-right: 1.25em;
        max-width: 13.75em;
    }

    &__content {
        padding-inline: 1em;
    }
}
