/**
 * Button styles
 *
 * Define global button styles
 */

%button {
    align-items: center;
    background-color: var(--wp--preset--color--black);
    color: var(--wp--preset--color--white);
    cursor: pointer;
    display: inline-flex;
    font-family: var(--wp--preset--font-family--headline);
    line-height: 1;
    padding: .5em 1em;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        background-color: var(--wp--preset--color--orange);
    }
}

%button--large {
    @extend %button;
    @extend %copy--news;
    padding-inline: 4em;
}

%button--primary {
    @extend %button;
    background-color: var(--wp--preset--color--orange);
    color: var(--wp--preset--color--white);
    font-size: 2.8125rem;
    font-weight: bold;
    padding-block: .25em;

    &:hover {
        background-color: var(--wp--preset--color--black);
    }
}

%button--secondary {
    @extend %button;
}

%button--text {
    @extend %button;
}

%button--cancel {
    @extend %button;
}
