.blog {

    .main {
        background: none;
    }

    .entry {
        background-color: rgba(255, 255, 255, .75);
        padding-bottom: 1em;

        &:not(:first-child) {
            margin-top: 4em;
        }

        &__header {
            border-bottom: .25em solid var(--wp--preset--color--orange);
            margin-bottom: 1em;
            margin-right: 1em;
            padding-block: .75em;
            padding-left: 1em;
        }

        &__title {
            margin: 0;
            text-transform: uppercase;

            a {
                text-decoration: none;

                &:hover {
                    color: var(--wp--preset--color--orange);
                }
            }
        }

        &__content {
            padding-inline: 1em;
        }

        &__footer {
            margin-top: 2em;
            padding-inline: 1em;
        }

        &__more-link {
            @extend %button;
        }
    }
}
