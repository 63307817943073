/**
 * Lists
 */
ul,
ol {

    &:not([class]) {

        li {

            &:not(:first-child) {
                margin-top: 1em;
            }
        }
    }
}

ol {

}

dl {

}
