.link-list {
    list-style: none;
    margin-top: 1em;
    padding: 0;

    &__item {

        &:not(:first-child) {
            margin-top: .5em;
        }

        &:not(.link-list__item--website) {
            padding-left: 1em;
        }
    }
}

.link {
    align-items: center;
    display: inline-flex;
    font-family: var(--wp--preset--font-family--headline);
    font-weight: bold;
    gap: .5em;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        color: var(--wp--preset--color--orange);
    }

    &::before {
        content: var(--img);
    }

    &--instagram {
        --img: url("../img/icon-instagram.svg");
    }

    &--facebook {
        --img: url("../img/icon-facebook.svg");
    }

    &--youtube {
        --img: url("../img/icon-youtube.svg");
    }

    &--tiktok {
        --img: url("../img/icon-tiktok.svg");
    }

    &--spotify {
        --img: url("../img/icon-spotify.svg");
    }
}
