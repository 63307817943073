.entry {

    &__wrapper {
        display: grid;
        gap: 2.5em;
        grid-template-areas:
            "sidebar"
            "content";

        @media (--desktop) {
            gap: 1.5em;
            grid-template-areas: "sidebar content";
            grid-template-columns: 1fr 2fr;
        }
    }

    &__sidebar {
        grid-area: sidebar;
    }

    &__header {
        margin-bottom: 2em;

        &--indent {

            @media (--desktop) {
                padding-left: calc(33.333% + 1em);
            }
        }
    }

    &__image {
        text-align: center;

        @media (--desktop) {
            padding-top: 7.5em;
        }

        .wp-post-image {
            max-width: 10em;

            @media (--desktop) {
                max-width: none;
            }
        }

        .single-gig & {
            padding-top: 0;
        }
    }

    &__video {
        grid-area: video;
        max-width: 100%;
    }

    &__content {
        grid-area: content;

        @media (--desktop) {
            padding-inline: var(--container-padding);
        }

        &:not(:only-child) {
            padding-left: 0;
        }
    }

    &__date {
        @extend %copy--news;
    }

    &__title {
        text-transform: uppercase;

        .entry__date + & {
            margin-top: 0;
        }
    }

    &__footer {
        margin-top: 2em;
    }
}
