/**
 * Header
 */
.header {
    color: var(--wp--preset--color--cyan);
    position: sticky;
    top: var(--adminbar__height);
    z-index: 25;

    &::after {
        background-color: var(--wp--preset--color--black);
        content: "";
        display: block;
        height: var(--header-bar);
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        .nav-is--visible & {
            background-color: var(--wp--preset--color--cyan);
        }
    }

    &__container {
        @extend %container;
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 15;
    }

    &__wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: flex-end;
    }
}
