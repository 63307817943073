.tabs-list {
    display: flex;
    gap: .5em;
    justify-content: center;
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    @media (--desktop) {
        gap: 1em;
    }
}

.tab-control {
    background-color: transparent;
    border: 0;
    display: inline-block;
    font-family: var(--wp--preset--font-family--heading);
    font-size: 1.125rem;
    font-weight: bold;
    padding: .5em 1em;
    text-decoration: none;
    text-transform: uppercase;

    @media (--desktop) {
        font-size: 1.25rem;
    }

    &:hover,
    &--active {
        background-color: var(--wp--preset--color--black);
        color: var(--wp--preset--color--white);
    }
}

.tab-panel {

    &--hidden {
        display: none;
    }
}
