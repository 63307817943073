.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 10;

    &::before {
        background-image: var(--footer-back, url("../img/bg-footer-back.svg"));
        background-position: center bottom;
        background-repeat: repeat-x;
        bottom: 0;
        content: "";
        display: block;
        height: 295px;
        position: fixed;
        width: 100%;
    }
}
