/**
 * Logo
 *
 * a.custom-logo-link
 *   img.custom-logo
 */
.custom-logo-link {
    display: inline-block;
    padding-top: .5em;
    position: relative;
    z-index: 10;

    @media (--desktop) {
        padding-top: 1.5em;
    }
}

.custom-logo {
    height: auto;
    width: 11.625em;

    @media (--desktop) {
        width: auto;
    }
}
