.sponsors {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    margin-block: 1em;

    &--header {
        display: none;
        justify-content: flex-end;
        padding-top: .5em;

        @media (--desktop-full) {
            display: flex;
            padding-right: 0;
        }
    }

    &--main {

        @media (--desktop-full) {
            display: none;
        }
    }

    img {
        max-height: 100%;
        height: 2.25em;
        width: 100%;
    }
}
