/**
 * Custom Properties
 *
 * Defining custom properties
 * usage: `color: var(--color-primary);`
 */
:root {
    --adminbar__height: 0;
    --animation__speed: .2s;
    --animation__speed--slow: .4s;
    --content-width: 58.75rem;
    --radius: 0;
    --logo-size: 367px;
    --container-padding: 1.5rem;
    --header-bar: 3.125rem;

    @media (--desktop) {
        --header-bar: 4.875rem;
    }

    @media (--adminbar--full) {
        --container-padding: 4rem;
    }
}
