/**
 * Footer
 */
.footer {
    align-items: center;
    background-image: var(--footer-front, url("../img/bg-footer-front.svg"));
    background-position: center top;
    background-repeat: repeat-x;
    color: var(--wp--preset--color--white);
    display: flex;
    min-height: 20rem;
    padding-bottom: 2em;
    padding-top: 10em;
    position: fixed;
    top: 100%;
    transform: translateY(-6em);
    transition: all .2s;
    width: 100%;
    z-index: 10;

    .scrolled--max & {
        transform: translateY(calc(-100% + 2em));
    }

    @media (--desktop) {
        padding-top: 4em;
    }

    &__container {
        text-align: center;
        @extend %container;
    }
}
