/**
 * <nav>
 */
nav {

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
    }
}
