/**
 * <h>
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--wp--preset--color--black);
    font-family: var(--wp--preset--font-family--heading);
}

h1 {
    @extend %heading--primary !optional;
}

h2 {
    @extend %heading--secondary !optional;
}

h3 {
    @extend %heading--teritary !optional;
}

h4 {
    @extend %heading--quaternary !optional;
}

h5 {
    @extend %heading--quinary !optional;

}

h6 {
    @extend %heading--senary !optional;
}
