.home-posts {
    margin-inline: calc(-1 * var(--container-padding));
    margin-top: 3em;
    padding-inline: var(--container-padding);
    text-align: center;

    &__wrapper  {
        display: grid;
        gap: 1.25em;

        @media (--adminbar--sticky) {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

.home-post {
    background-color: var(--wp--preset--color--white);
    text-align: left;

    &__date {
        @extend %copy--small;
        background-color: var(--wp--preset--color--black);
        color: var(--wp--preset--color--white);
        padding: .25rem 1.25rem;
        text-transform: uppercase;
    }

    &__title {
        @extend %copy--news;
        margin: 0;
        padding: .5em 1.25rem;

        a {
            text-decoration: none;
        }
    }

    &__content {
        padding: 0 1.25rem 1.25rem;
    }

    &__footer {
        padding-inline: 1em 1.25rem;
    }

    &__more-link {
        font-size: var(--wp--preset--font-size--xl);
        text-decoration: none;
    }
}

.more-posts {
    @extend %button;
    margin-top: 2em;
    padding-inline: 4em;
}
