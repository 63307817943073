/**
 * Paragraph (optimized)
 *
 * @see: https://www.smashingmagazine.com/2020/07/css-techniques-legibility/
 */
p {
    margin: calc(1ex / .32) 0;

    &.is-style-highlighted {
        @extend %copy--overhead;
        font-size: 1.75rem;
        text-transform: uppercase;
    }
}
